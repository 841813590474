<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
    />
    <scheme-park-area />
    <!-- <scheme-park-area02 />
    <scheme-park-area-mission />
    <scheme-park-area-vitality />
    <scheme-park-area-lead /> -->
    <land-contact-way
      dark
      back-color="blue-grey darken-4"
    />
  </section>
</template>

<script>
  import app from '@/api/co.app'
  import mixParkBanner from '@/pages/mixins/park/mix.park.banner'

  export default {
    metaInfo: { title: '魅力园区' },
    components: {
      SchemeParkArea: () => import('@/pages/sections/ParkArea.vue')
      // SchemeParkAreaMission: () => import('@/pages/sections/ParkAreaMission.vue'),
      // SchemeParkAreaVitality: () => import('@/pages/sections/ParkAreaVitality.vue'),
      // SchemeParkAreaLead: () => import('@/pages/sections/ParkAreaLead.vue')
    },
    mixins: [mixParkBanner],
    data () {
      return {
        options: {
          toolbar: true,
          url: 'data-source'
        }
      }
    },
    created () {
      this.initBannerParams({
        siteId: app.init.siteId,
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()
    },
    beforeDestroy () {
    }
  }
</script>
